import WsProvider from './provider/WSProvider'

var serviceName = "webservis";
var moduleName = "blogKategori";

var haberService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    hKategoriListe(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "blogKategoriListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },/*
    hKategoriSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "blogKategoriSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },*/

    kategoriDurumGuncelle(kategoriID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            durum: durum,
            bkategoriID: kategoriID,
            serviceName: moduleName,
            methodName: "blogAktifPasifEt"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },


    kategoriTanimla(baslik, menuBaslik, menuIcon, menuGoster, menuKategoriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            baslik,
            menuBaslik,
            menuGoster,
            goster: menuGoster,
            durum: 1,
            seoBaslik: baslik,
            seoIcerik: baslik,
            ustMenuID: 0,
            menuKategoriID,
            menuIcon,
            menuSiraNo: 0,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: 1,
            seoSayac: 0,
            serviceName: moduleName,
            methodName: "blogKategoriEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

    kategoriGuncelle(bkategoriID, baslik, menuGoster, menuBaslik, menuIcon, menuKategoriID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            bkategoriID,
            baslik,
            menuGoster,
            goster: menuGoster,
            menuBaslik,
            menuIcon,
            menuKategoriID,
            ustMenuID: 0,
            menuSiraNo: 0,
            durum: 1,
            serviceName: moduleName,
            methodName: "blogKategoriGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

        return result;
    },

}

export default haberService