<template>
    <div class="page-table haberTanim  mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.haberler.tanimlama.title")}}
        </div>
        
        <el-row :gutter="10" v-loading="loading"
            :element-loading-text='$t("src.views.apps.haberler.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="haberlerForm" :rules="rulesHaberler" ref="haberlerForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft" >
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.haberler.tanimlama.baslik")' prop="baslik">:
                                        <el-input size="small"
                                        :disabled="disabled"
                                        v-model="haberlerForm.baslik"
                                        v-on:blur="linkTespit();"
                                        :placeholder='$t("src.views.apps.messages.enterBultenTitle")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.haberler.tanimlama.altbaslik")' prop="altbaslik">:
                                        <el-input size="small"
                                            :disabled="disabled"
                                            v-model="haberlerForm.altbaslik"
                                            :placeholder='$t("src.views.apps.messages.enterSubTitle")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.haberler.tanimlama.kategoriler")' :prop="haberlerForm.kategori == '' ? '' : 'kategori'">:
                                        <el-select v-model="haberlerForm.kategori" style="width: 100%" size="mini">
                                            <div v-loading="catLoading">
                                                <el-option v-for="item in kategoriListe" :key="item.bkategoriID" :label="item.baslik" :value="item.bkategoriID"></el-option>
                                            </div>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.haberler.tanimlama.icerik")' prop="icerik">:
                                        <ckeditor :editor="editor"
                                        :config="editorConfig"
                                        v-model="haberlerForm.icerik"
                                        :disabled="disabled"
                                        @blur="seoIcerikTespit(); $store.commit('changeTabsClose', false)"></ckeditor>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item>
                                        <el-checkbox v-model="videoVis">{{$t("src.views.apps.haberler.guncelleme.videoOlsunMu")}}</el-checkbox>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>

                        <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                            <el-row :gutter="10">
                                <el-col v-if="videoVis" style="float: right" :lg="12" :md="12" :sm="24" :xs="24">
                                    <el-collapse-item class=" animated fadeInDown card-shadow--medium" :title='$t("src.views.apps.haberler.guncelleme.video")' name="2">
                                        <el-row>
                                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                <el-form-item :label='$t("src.views.apps.haberler.guncelleme.video")' prop="video">:
                                                    <el-input size="small"
                                                    type="textarea"
                                                    :rows="5"
                                                    v-model="haberlerForm.video"
                                                    :placeholder='$t("src.views.apps.messages.enterVideoURL")'></el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </el-collapse-item>
                                </el-col>
                            </el-row>
                        </el-collapse>
                        

                        <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.genel.resimAyarlari")' name="6">
                                <el-row>
                                    <Resim functionName="haberTanimlaImage" :selectedsFromServer="selectedImagesFromServer"></Resim>
                                </el-row>
                            </el-collapse-item>
                        </el-collapse>

                        <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                            <draggable data-source="juju" class="list-group" group="a">
                                <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.genel.seoAyarlari")' name="3">
                                    <seo :modulName="'haber'" :baslik="haberlerForm.baslik" :icerik="haberlerForm.icerik" v-on:changeSeo="handleSeo"></seo>
                                </el-collapse-item>
                            </draggable>
                        </el-collapse>
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.genel.durum")'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select
                                            size="small"
                                            :disabled="disabled"
                                            v-model="haberlerForm.durum"
                                            :placeholder='$t("src.views.apps.messages.addBultenTitle")'
                                            style="width: 100%">
                                            <el-option value="0" label="Taslak">{{$t("src.views.apps.haberler.guncelleme.taslak")}}</el-option>
                                            <el-option value="1" label="Yayınla">{{$t("src.views.apps.haberler.guncelleme.yayinla")}}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-button size="mini" v-on:click="haberTanimlama('haberlerForm',haberlerForm.durum)" class="onayBtn" type="primary">
                                    <label v-if="haberlerForm.durum === '1'">
                                        {{$t('src.views.apps.haberler.tanimlama.haberEkle')}}</label>
                                    <label v-else>Kaydet</label>
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="300" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.messages.clearFormAlert')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('haberlerForm')">
                                            {{$t('src.views.apps.genel.yes')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.genel.no')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        {{$t('src.views.apps.genel.temizle')}}
                                    </el-button>
                                </el-popover>
                            </el-row>
                        </el-collapse-item>


                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.haberler.tanimlama.tarih")' name="10">
                                <el-row :gutter="5">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                        <el-row>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-form-item style="position: relative;top: -25px"
                                                    :label='$t("src.views.apps.haberler.tanimlama.baslamaTarihi")'
                                                    prop="tarih">
                                                    <el-date-picker  size="small" class="select-wide" type="datetime"
                                                        :disabled="disabled"
                                                        :placeholder='$t("src.views.apps.message.selectDate")'
                                                        :picker-options="dateRangeOptions"
                                                        v-model="haberlerForm.tarih"
                                                        format="yyyy-MM-dd HH:mm:ss"
                                                        value-format="yyyy-MM-dd HH:mm:ss">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-checkbox v-if="!checkedTarih" style="margin-bottom: 15px" v-model="checkedTarih">
                                                    {{$t("src.views.apps.haberler.tanimlama.bitisTarihi")}}
                                                </el-checkbox>
                                                <el-col v-if="checkedTarih" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                    <el-form-item style="position: relative;top: -20px"
                                                        :label='$t("src.views.apps.haberler.tanimlama.bitisTarihi")'
                                                        prop="bitisTarihi">:
                                                        <el-date-picker size="small" class="select-wide" type="datetime"
                                                            :disabled="disabled"
                                                            :placeholder='$t("src.views.apps.message.selectDate")'
                                                            :picker-options="dateRangeOptions"
                                                            v-model="haberlerForm.bitisTarihi"
                                                            format="yyyy-MM-dd HH:mm:ss"
                                                            value-format="yyyy-MM-dd HH:mm:ss">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <!-- <konumlar :modulName="'haber'" :resim="haberlerForm.resimKonum" :menu="haberlerForm.menuKonum" :galeri="haberlerForm.galeriKonum" v-on:changeKonum="handleKonum"></konumlar> -->

                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";

    import haberService from '../../../WSProvider/HaberService'
    import haberKategoriService from '../../../WSProvider/HaberKategoriService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Seo from '@/components/Seo.vue'
    import Konumlar from '@/components/Konumlar.vue'

    var moment = require('moment');
    let time = moment().format('YYYY-MM-DD HH:mm:ss');

    export default {
        name: "HaberlerTanimla",
        components: {
            draggable,
            Resim,
            Seo,
            Konumlar
        },
        watch: {
            imageList: function (val) {
                if (val.length === 1) {
                    this.haberlerForm.resimKonum = "1";
                    this.haberlerForm.galeriKonum = "0";
                } else if (val.length > 1) {
                    this.haberlerForm.galeriKonum = "1";
                } else {
                    this.haberlerForm.resimKonum = "0";
                    this.haberlerForm.galeriKonum = "0";
                }
            },
        },
        computed: {
            imageList() {
                return this.haberlerForm.resim
            }
        },
        mounted() {
            this.getKategoriCount();

            const self = this;
            EventBus.$on("haberTanimlaImage", function(bool, imageArray) {
                if (bool) {
                    self.haberlerForm.resim = imageArray;
                }
            });

            EventBus.$on("haberTanimlaBelge", function(bool, belgeArray) {
                if (bool) {
                    self.haberlerForm.belge = belgeArray;
                }
            });
        },
        data() {
            return {
                totalKategori: 0, // kategorilerin sayısını ilk önce cekerek kategori listesini komple cekmek için
                catLoading:false,
                kategoriListe: [],

                loading : false,
                selectedImagesFromServer: [],
                path: haberService.path,
                imagePath: haberService.imagePath,
                belgePath: haberService.belgePath,

                videoVis: false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
                visibleSil: false,
                disabled: false,
                checkedTarih: false,
                dateRangeOptions: {
                    firstDayOfWeek: 1
                },
                haberlerForm: {
                    kategori: '',
                    baslik: '',
                    altbaslik: '',
                    video: "",
                    icerik: '',
                    resim: [],
                    belge: [],
                    link: '',
                    resimKonum: '0',
                    menuKonum: '0',
                    galeriKonum: '0',
                    tarih: time,
                    bitisTarihi: '',
                    durum: '1',
                    seoBaslik: '',
                    seoIcerik: '',
                },
                rulesHaberler: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Haber Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    kategori: [{
                        required: true,
                        message: "Lütfen Kategori Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                    altbaslik: [{
                        required: true,
                        message: "Lütfen Haber Alt Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    icerik: [{
                        required: true,
                        message: "Lütfen İçerik Giriniz",
                        trigger: 'blur'
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "Tavsiye edilen SEO Başlık limitini aştınız.",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                        max: 160,
                        message: "Tavsiye edilen SEO İçerik limitini aştınız.",
                        trigger: 'change'
                    }],
                },
            }
        },
        methods: {
            linkTespit() {
                this.haberlerForm.seoBaslik = this.haberlerForm.baslik.substring(0, 60);     
            },
            seoIcerikTespit() {
                this.haberlerForm.seoIcerik = this.haberlerForm.baslik.substring(0, 60);     
            },
            handleSeo(seoBaslik,seoIcerik) {
                this.haberlerForm.seoBaslik = seoBaslik;
                this.haberlerForm.seoIcerik = seoIcerik;
            },

            handleKonum(resimKonum,menuKonum,galeriKonum) {
                // normal sartlarda ana sitede sayfanın düzeni kontrol edilebilir oluyormus. Yanı eklenen resimlerin konumu yukarıda mı asagıda mı olsun gibi. Ama suan da ona uygun bir yapı olmadıgı için component yukarıda yorum satırı olarak kaldı. Lazım oldugunda yorum satırı kaldırılıp kullanılır.
                this.haberlerForm.resimKonum = resimKonum;
                this.haberlerForm.menuKonum = menuKonum;
                this.haberlerForm.galeriKonum = galeriKonum;
            },
            
            getKategoriListe() {
                this.catLoading = true;
                haberKategoriService.hKategoriListe(1, 0 , this.totalKategori).then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.kategoriListe = response.data;
                    }
                    this.catLoading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){  
                            this.kategoriListe = [];
                            this.haberlerForm.kategori = "";
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.catLoading = false;
                })
            },

            getKategoriCount() {
                haberKategoriService.hKategoriSayisi(1).then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token);
                        this.totalKategori = response.data;
                        this.getKategoriListe();
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){  
                            this.totalKategori = 0
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

            haberTanimlama(formName, durum) {
                this.loading = true;
                this.$refs[formName].validate((valid, errors) => {
                    if (valid) {
                        this.$confirm("Haberi tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            var resimUzunluk = this.haberlerForm.resim.length;
                            var sunucudanEklenenResimler = this.selectedImagesFromServer.join(","); // sunucudan secilen resimlerin elimizde sadece isimleri var. Bu isimleri backend , ile ayrılmış bir string ile alıyor. Suan sunucudan fotograf sectirme yaptırmadıgımız için (resim componentine baktıgınızda yorum satırında zaten) suan için yorum satırı yapmadım. Zaten tanımlarken gönderiliyor. Sizin yapacagınız tek sey resim komponentindeki sunucudan resimlerin listelendiği dialogun yorum satırını kaldırmak!

                            haberService.haberTanimlama(this.haberlerForm.kategori, this.haberlerForm.baslik, this.haberlerForm.altbaslik, this.haberlerForm.icerik, sunucudanEklenenResimler, this.haberlerForm.resim.length > 0 ? this.haberlerForm.resim : "", resimUzunluk, this.haberlerForm.video, this.haberlerForm.resimKonum, this.haberlerForm.menuKonum, this.haberlerForm.galeriKonum, durum, "dinamik", this.haberlerForm.baslik, this.haberlerForm.icerik, this.haberlerForm.tarih, this.haberlerForm.bitisTarihi).then((response) => {
                                if(response.status == 200){
                                    localStorage.setItem("userDataBGSurec", response.token)
                                    this.resetForm(formName)
                                    EventBus.$emit("haberList", true)
                                    functions.sayfaKapat("blogtanimla", this);
                                }
                                this.loading = false;
                                notification.Status("success", this, response.msg)
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            })
                        }).catch((e) => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })  
                            this.loading = false
                        }
                    }
                })
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
            },

        }
    }

</script>
